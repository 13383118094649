import React from 'react';
import Unity, {UnityContext} from 'react-unity-webgl';
import './index.css';

export default function UnityWindow() {

    const unityContext = new UnityContext({
        loaderUrl: "/UnityWindow/Build/UnityWindow.loader.js",
        dataUrl: "/UnityWindow/Build/UnityWindow.data",
        frameworkUrl: "/UnityWindow/Build/UnityWindow.framework.js",
        codeUrl: "/UnityWindow/Build/UnityWindow.wasm",
    });


    return <Unity unityContext={unityContext}/>;
}