import './App.css';
import UnityWindow from "./unity-window";

function App() {
    return (
        <div className="App">
            <UnityWindow/>
        </div>
    );
}

export default App;
